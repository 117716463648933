import ApiService from "./api.service";

export interface Pharmacy {
    id?: string;
    mail: string;
    name: string;
    url: string;
    city: string;
    address: string;
}

const PharmacyService = {
    async ping (): Promise<string> {
        return (await ApiService.get("/pharmacy/ping") as { pharmacy: string }).pharmacy;
    },

    async register ({ mail, name, password }: { mail: string; name: string; password: string }): Promise<void> {
        await ApiService.post("/pharmacy/register", {
            mail,
            name,
            password
        });
    },

    async login ({ mail, password }: { mail: string; password: string }): Promise<void> {
        await ApiService.post("/pharmacy/login", {
            mail,
            password
        });
    },

    async disconnect (): Promise<void> {
        await ApiService.get("/pharmacy/disconnect");
    },

    async getSettings (): Promise<Pharmacy> {
        return await ApiService.get("/pharmacy/settings") as Pharmacy;
    },

    async getByUrl (url: string): Promise<Pharmacy> {
        return await ApiService.get(`/pharmacy/url/${url}`) as Pharmacy;
    },

    async update (settings: Partial<Pharmacy>): Promise<void> {
        await ApiService.put("/pharmacy/settings", settings);
    }
};

export default PharmacyService;
