<template>
    <div class="admin-layout">
        <div class="sidebar">
            <slot name="sidebar" />
        </div>
        <div
            ref="content"
            class="content"
        >
            <slot name="content" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
.admin-layout {
    display: grid;
    grid-template-areas: "sidebar content";
    grid-template-rows: auto;
    grid-template-columns: 380px auto;
    align-items: stretch;
    height: 100vh;
    min-width: 1180px;

    @media screen and (max-width: 1400px) {
        grid-template-columns: 224px auto;
    }

    .sidebar {
        grid-area: sidebar;
        height: 100vh;
        overflow: auto;
    }

    .content {
        scroll-behavior: smooth;
        grid-area: content;
        height: 100vh;
        overflow: auto;
    }
}
</style>
