<template>
    <div
        class="menu-button"
        :class="{active}"
        @click="$emit('click')"
    >
        <div class="icon">
            <FontAwesomeIcon :icon="icon" />
        </div>
        <div class="name">
            {{ name }}
        </div>
    </div>
</template>

<script lang="ts">
import FontAwesomeIcon from "@/utils/fontawesome";

export default {
    name: "MenuButton",
    components: {
        FontAwesomeIcon
    },
    props: {
        active: Boolean,
        name: { type: String, default: "" },
        icon: { type: String, default: "" }
    },
    emits: ["click"]
};
</script>

<style scoped lang="scss">
.menu-button {
    height: 96px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-lightest);
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
    padding-left: 48px;
    cursor: pointer;
    transition: ease-in-out .1s;
    opacity: .7;

    &.active {
        background: var(--color-shadow-soft);
        opacity: 1;
    }

    &:hover:not(.active) {
        background: var(--color-light-soft);
        opacity: 1;
    }

    .icon {
        font-size: 32px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    .name {
        @media screen and (max-width: 1400px) {
            display: none;
        }
    }
}
</style>
