
import { defineComponent } from "vue";
import _ from "lodash";
import {
    add as dateAdd,
    getDaysInMonth,
    startOfMonth,
    endOfMonth,
    startOfISOWeek,
    endOfISOWeek,
    isBefore,
    getDay, isSameDay
} from "date-fns";
import FontAwesomeIcon from "@/utils/fontawesome";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import AppointmentStore from "@/stores/appointment.store";
import { formatDay } from "@/utils/date";
import * as TimeOption from "@/components/timetable/timeOptions";
import { days } from "@/components/timetable/timeOptions";
import AppointmentViewMonthDay from "@/components/appointment/AppointmentViewMonthDay.vue";
import Widget from "@/components/template/elements/Widget.vue";

export default defineComponent({
    components: {
        Widget,
        AppointmentViewMonthDay,
        FontAwesomeIcon,
        FormSelect
    },
    data () {
        return {
            currentDate: new Date(),
            currentMonth: 0,
            currentYear: 0,
            isLoading: true,
            appointmentState: AppointmentStore.getState(),
            formatDay,
            TimeOption,
            days
        };
    },
    computed: {
        dayOptions () {
            const daysInMonth = getDaysInMonth(this.currentDate);
            return Array.from(new Array(daysInMonth), (x, i) => {
                return {
                    id: (i + 1).toString(),
                    value: i + 1,
                    name: (i + 1).toString().padStart(2, "0")
                };
            });
        },
        weeks () {
            const weeks = [];

            const startDay = startOfISOWeek(startOfMonth(this.currentDate));
            const endDay = endOfISOWeek(endOfMonth(this.currentDate));

            let currentDay = startDay;

            while (isBefore(currentDay, endDay)) {
                if (getDay(currentDay) === 1) {
                    const days = [];
                    for (let day = 0; day < 7; day++) {
                        days.push(currentDay);
                        currentDay = dateAdd(currentDay, { days: 1 });
                    }
                    weeks.push(days);
                }
            }

            return weeks;
        }
    },
    watch: {
        currentMonth () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
        },
        currentYear () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
        },
        async currentDate () {
            this.currentMonth = this.currentDate.getMonth();
            this.currentYear = this.currentDate.getFullYear();

            this.isLoading = true;
            await AppointmentStore.setAppointmentPeriod(startOfMonth(this.currentDate), dateAdd(startOfMonth(this.currentDate), { months: 1 }));
            this.isLoading = false;
        }
    },
    async mounted () {
        const now = new Date();

        this.currentMonth = now.getMonth();
        this.currentYear = now.getFullYear();

        this.isLoading = true;
        await AppointmentStore.setAppointmentPeriod(startOfMonth(this.currentDate), dateAdd(startOfMonth(this.currentDate), { months: 1 }));
        this.isLoading = false;
    },
    methods: {
        async nextMonth (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { months: 1 });
        },
        async previousMonth (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { months: -1 });
        },
        getAppointmentsForDay (date: Date) {
            return _.filter(this.appointmentState.appointments, (appointment) => isSameDay(appointment.startDate, date));
        }
    }
});
