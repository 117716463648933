<template>
    <div class="view-month-card">
        <div class="line">
            {{ appointment.firstName }} {{ appointment.lastName }}
        </div>
        <div class="card">
            <div>{{ appointment.firstName }} {{ appointment.lastName }}</div>

            <div
                v-if="appointment.mail || appointment.phone"
                class="description"
            >
                <span v-if="appointment.mail">
                    {{ appointment.mail }}
                </span>
                <span
                    v-if="appointment.mail && appointment.phone"
                    class="separation"
                > | </span>
                <span v-if="appointment.phone">
                    {{ appointment.phone }}
                </span>
            </div>

            <div class="description">
                <span class="duration">
                    {{
                        formatHour(appointment.startDate)
                    }}
                    <FontAwesomeIcon icon="caret-right" />
                    {{ formatHour(appointment.endDate) }}
                </span><br>
                {{ appointmentState.appointmentTypes[appointment.type]?.name || "Inconnu" }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formatHour } from "@/utils/date";
import { Appointment } from "@/services/appointment.service";
import AppointmentStore from "@/stores/appointment.store";

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        appointment: {
            type: Object as PropType<Appointment>,
            required: true
        }
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            formatHour
        };
    }
});
</script>

<style scoped lang="scss">
.view-month-card {
    position: relative;
    text-align: left;
    padding: 2px 0;

    .line {
        background: var(--color-primary);
        color: var(--color-text-lightest);
        padding: 2px 4px;
        border-radius: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .card {
        display: none;
        pointer-events: none;
        background: var(--color-primary);
        color: var(--color-text-lightest);
        z-index: 5;
        position: absolute;
        left: 0;
        top: 2px;
        padding: 2px 4px;
        box-shadow: 0 0 16px var(--color-shadow-hard);
        border-radius: 8px;
        width: 256px;

        .separation, .duration {
            opacity: 0.7;
        }

        .description {
            margin-top: 8px;
        }
    }

    &:hover {
        .card {
            display: block;
        }
    }
}
</style>
