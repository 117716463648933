import { reactive, readonly } from "vue";

export abstract class Store<StateType extends Record<string, unknown>> {
    protected state: StateType;

    constructor () {
        const data = this.data();
        this.state = reactive(data) as StateType;
    }

    protected abstract data(): StateType

    public getState (): StateType {
        return readonly(this.state) as StateType;
    }
}
