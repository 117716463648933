const ApiService = {
    baseUrl: "",
    init (baseUrl: string): void {
        this.baseUrl = baseUrl;
    },
    async get (route: string): Promise<unknown> {
        return this.fetch(route, "get");
    },
    async put (route: string, payload?: unknown): Promise<unknown> {
        return this.fetch(route, "put", payload);
    },
    async post (route: string, payload?: unknown): Promise<unknown> {
        return this.fetch(route, "post", payload);
    },
    async delete (route: string, payload?: unknown): Promise<unknown> {
        return this.fetch(route, "delete", payload);
    },
    async fetch (route: string, method: "get" | "post" | "put" | "delete", payload?: unknown): Promise<unknown> {
        const url = this.baseUrl + route;
        const response = await fetch(url, {
            method,
            headers: {
                Accept: "application/json",
                "Content-type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(payload)
        });

        if (response.status > 299) {
            throw new Error("Error while fetching resource");
        }

        return response.json();
    }
};

export default ApiService;
