
import { defineComponent, PropType } from "vue";

import {
    getDate, isSameDay,
    isSameMonth
} from "date-fns";
import { Appointment } from "@/services/appointment.service";
import AppointmentViewMonthCard from "@/components/appointment/AppointmentViewMonthCard.vue";

export default defineComponent({
    components: { AppointmentViewMonthCard },
    props: {
        date: {
            type: Date as PropType<Date>,
            required: true
        },
        currentDate: {
            type: Date as PropType<Date>,
            required: true
        },
        appointments: {
            type: Array as PropType<Array<Appointment>>,
            required: true
        }
    },
    computed: {
        displayDay (): number {
            return getDate(this.date);
        },
        isCurrentMonth (): boolean {
            return isSameMonth(this.date, this.currentDate);
        },
        isToday (): boolean {
            return isSameDay(this.date, new Date());
        }
    }
});
