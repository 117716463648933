
import { defineComponent } from "vue";
import * as Yup from "yup";
import Widget from "@/components/template/elements/Widget.vue";
import AppointmentStore from "@/stores/appointment.store";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";
import { Appointment, DaySlots } from "@/services/appointment.service";
import FormInput from "@/components/template/elements/FormInput.vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import DateSelection from "@/components/appointment/DateSelection.vue";
import ResponsiveInput from "@/components/mobile/elements/ResponsiveInput.vue";
import AppointmentHourSelect from "@/components/appointment/AppointmentHourSelect.vue";

export default defineComponent({
    components: {
        AppointmentHourSelect,
        DateSelection,
        FontAwesomeIcon,
        FormInput,
        FormSelect,
        FormSpacer,
        Widget
    },
    emits: ["select-date"],
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            appointmentOptions: [] as Array<{ id: string; value: number | string; name: string }>,
            selectedAppointment: "",
            availableDates: [] as DaySlots,
            selectedDate: "",
            selectedTime: 0,
            firstName: "",
            lastName: "",
            mail: "",
            phone: "",
            mailRules: Yup.string()
                .email("Vous devez entrer un email valide."),
            firstNameRules: Yup.string()
                .required("Vous devez entrer un prénom.")
                .min(2, "Votre prénom doit contenir au moins 2 caractères."),
            lastNameRules: Yup.string()
                .required("Vous devez entrer un nom de famille.")
                .min(2, "Votre nom de famille doit contenir au moins 2 caractères.")
        };
    },
    computed: {
        isFullfilled (): boolean {
            return (this.firstName !== "" && this.lastName !== "");
        }
    },
    watch: {
        "appointmentState.appointmentTypes": {
            immediate: true,
            deep: true,
            handler () {
                this.appointmentOptions = [];

                for (const appointmentType of Object.keys(this.appointmentState.appointmentTypes)) {
                    const appointment = this.appointmentState.appointmentTypes[appointmentType];
                    this.appointmentOptions.push({
                        name: `${appointment.name} (${appointment.duration}min)`,
                        id: appointment.id as string,
                        value: appointment.id as string
                    });
                }
            }
        },
        async selectedAppointment () {
            if (this.selectedAppointment !== "") {
                await AppointmentStore.loadDateSlots(this.selectedAppointment);
            }
        }
    },
    async mounted () {
        await AppointmentStore.load();
    },
    methods: {
        selectDate (date: string) {
            this.selectedDate = date;
            this.$emit("select-date", date);
        },
        selectTime (hour: number) {
            this.selectedTime = hour;
        },
        async checkForm (): Promise<boolean> {
            let isValid = true;

            isValid = isValid && await (this.$refs.firstNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.lastNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.mailInput as typeof ResponsiveInput).checkValidity();

            return isValid;
        },
        async submit () {
            const isValid = await this.checkForm();
            if (isValid) {
                const appointment: Partial<Appointment> = {
                    appointmentTypeId: this.selectedAppointment,
                    startDate: new Date(this.selectedTime),
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mail: this.mail,
                    phone: this.phone
                };

                await AppointmentStore.register(appointment);
                await AppointmentStore.loadAppointments();

                this.firstName = "";
                this.lastName = "";
                this.mail = "";
                this.selectedDate = "";
                this.selectedTime = 0;
                this.selectedAppointment = "";
            }
        }
    }
});
