<template>
    <div
        class="button"
        :class="color"
        @click="$emit('click')"
    >
        <div class="text">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "green"
        }
    },
    emits: ["click"]
};
</script>

<style scoped lang="scss">
.button {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 800;
    cursor: pointer;
    border-radius: 64px;
    box-shadow:
        var(--color-shadow-soft) 5px 5px 10px,
        var(--color-light-hard) -5px -5px 10px;
    transition: ease-in-out 0.2s;

    &.green {
        color: var(--color-text-lightest);
        background: var(--color-primary);
    }

    &.white {
        color: var(--color-primary);
    }

    .text {
        transition: ease-in-out 0.2s;
        opacity: 0.7;
    }

    &:hover {
        box-shadow:
            var(--color-shadow-soft) 3px 3px 6px,
            var(--color-light-hard) -3px -3px 6px;

        .text {
            opacity: 1;
        }
    }
}
</style>
