<template>
    <div class="hour-select">
        <div
            v-for="(availableDate, dateIndex) of availableDates"
            :key="availableDate"
            class="hour"
            :class="{
                selected: availableDate === selectedTime,
                'stick-left': isStickLeft(dateIndex),
                'stick-right': isStickRight(dateIndex)
            }"
            @click="selectTime(availableDate)"
        >
            {{ formatHour(new Date(availableDate)) }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { differenceInMinutes } from "date-fns";
import { DaySlots } from "@/services/appointment.service";
import { formatHour } from "@/utils/date";

export default defineComponent({
    props: {
        availableDates: {
            type: Array as PropType<DaySlots>,
            required: true
        },
        selectedTime: {
            type: String,
            default: ""
        },
        duration: {
            type: Number,
            required: true
        }
    },
    emits: ["select-time"],
    data () {
        return {
            formatHour
        };
    },
    methods: {
        selectTime (time: number): void {
            this.$emit("select-time", time);
        },
        isStickLeft (dateIndex: number): boolean {
            if (dateIndex > 0) {
                return differenceInMinutes(new Date(this.availableDates[dateIndex]), new Date(this.availableDates[dateIndex - 1])) === this.duration;
            }
            return false;
        },
        isStickRight (dateIndex: number): boolean {
            if (dateIndex < this.availableDates.length - 1) {
                return differenceInMinutes(new Date(this.availableDates[dateIndex + 1]), new Date(this.availableDates[dateIndex])) === this.duration;
            }
            return false;
        }
    }
});
</script>

<style scoped lang="scss">
.hour-select {
    margin-right: 32px;
}

.hour {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 32px;
    margin: 4px 4px;
    color: var(--color-text-lightest);
    cursor: pointer;
    background: var(--color-primary);
    border-radius: 16px;

    &.stick-left {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: 0;
        padding-left: 8px;
        width: 68px;
    }

    &.stick-right {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-right: 0;
        padding-right: 8px;
        width: 68px;

    }

    &.stick-right.stick-left {
        width: 72px;
    }

    &:hover {
        border: 3px solid var(--color-background);
    }

    &.selected {
        border: 3px solid var(--color-text);
    }
}
</style>
