import { Store } from "./model";

import PharmacyService, { Pharmacy } from "@/services/pharmacy.service";

interface PharmacyStoreData extends Record<string, unknown> {
    isConnected: boolean;
    pingTry?: boolean;
    pharmacy?: Pharmacy;
}

class PharmacyStore extends Store<PharmacyStoreData> {
    protected data (): PharmacyStoreData {
        return {
            isConnected: false
        };
    }

    async loadSettings (): Promise<void> {
        if (!this.state.pharmacy) {
            this.state.pharmacy = await PharmacyService.getSettings();
        }
    }

    async loadByUrl (url: string): Promise<void> {
        if (!this.state.pharmacy) {
            this.state.pharmacy = await PharmacyService.getByUrl(url);
        }
    }

    async register ({ mail, name, password }: { mail: string; name: string; password: string }): Promise<void> {
        await PharmacyService.register({ mail, name, password });
        this.state.isConnected = true;
        this.state.pingTry = true;
    }

    async login ({ mail, password }: { mail: string; password: string }): Promise<void> {
        await PharmacyService.login({ mail, password });
        this.state.isConnected = true;
        this.state.pingTry = true;
    }

    async isConnected (): Promise<boolean> {
        if (!this.state.pingTry) {
            await this.ping();
        }
        return this.state.isConnected;
    }

    async ping (): Promise<void> {
        this.state.pingTry = true;
        try {
            this.state.pharmacyId = await PharmacyService.ping();
            this.state.isConnected = true;
        }
        catch (error) {
            delete this.state.pharmacyId;
            delete this.state.isConnected;
        }
    }

    async update (settings: Partial<Pharmacy>): Promise<void> {
        this.state.pharmacy = { ...this.state.pharmacy, ...settings } as Pharmacy;
        await PharmacyService.update(settings);
    }

    async disconnect (): Promise<void> {
        await PharmacyService.disconnect();
        document.location.reload();
    }
}

const pharmacyStore = new PharmacyStore();
export default pharmacyStore as PharmacyStore;
