
import { defineComponent } from "vue";
import Logo from "@/components/template/Logo.vue";
import MenuButton from "@/components/template/menu/MenuButton.vue";

const menuList = [
    {
        icon: "list",
        path: "home",
        name: "Accueil"
    },
    {
        icon: "calendar",
        path: "appointment",
        name: "Rendez-vous"
    },
    {
        icon: "calendar-alt",
        path: "timetable",
        name: "Emploi du temps"
    },
    // {
    //    icon: "file-invoice",
    //    path: "billing",
    //    name: "Facturation"
    // },
    {
        icon: "cog",
        path: "settings",
        name: "Paramètres"
    }
];

export default defineComponent({
    components: { MenuButton, Logo },
    data () {
        return {
            menuList
        };
    },
    methods: {
        goToPage (page: string): void {
            this.$router.push({ name: page });
        },
        isCurrentPage (page: string): boolean {
            return page === this.$route.name;
        }
    }
});
