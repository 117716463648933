
import { defineComponent } from "vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import Sidebar from "@/components/template/Sidebar.vue";

export default defineComponent({
    components: {
        AdminLayout,
        Sidebar
    },
    computed: {
        displayAdminLayout (): boolean {
            return this.$route.meta.pharmacyAccess;
        }
    }
});
