<template>
    <div class="view-month">
        <div class="day-selection">
            <FontAwesomeIcon
                icon="angle-double-left"
                class="navigation"
                @click="previousMonth"
            />
            <div class="month">
                <FormSelect
                    v-model="currentMonth"
                    input-id="month"
                    label=""
                    :options="TimeOption.months"
                    class="select select-month"
                />
            </div>
            <FontAwesomeIcon
                icon="angle-double-right"
                class="navigation"
                @click="nextMonth"
            />
        </div>
        <Widget class="view">
            <table>
                <tr>
                    <th
                        v-for="day in days"
                        :key="day.value"
                    >
                        {{ day.name }}
                    </th>
                </tr>
                <tr
                    v-for="week in weeks"
                    :key="week.id"
                >
                    <td
                        v-for="day in week"
                        :key="day"
                    >
                        <AppointmentViewMonthDay
                            :date="day"
                            :current-date="currentDate"
                            :appointments="getAppointmentsForDay(day)"
                        />
                    </td>
                </tr>
            </table>
        </Widget>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";
import {
    add as dateAdd,
    getDaysInMonth,
    startOfMonth,
    endOfMonth,
    startOfISOWeek,
    endOfISOWeek,
    isBefore,
    getDay, isSameDay
} from "date-fns";
import FontAwesomeIcon from "@/utils/fontawesome";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import AppointmentStore from "@/stores/appointment.store";
import { formatDay } from "@/utils/date";
import * as TimeOption from "@/components/timetable/timeOptions";
import { days } from "@/components/timetable/timeOptions";
import AppointmentViewMonthDay from "@/components/appointment/AppointmentViewMonthDay.vue";
import Widget from "@/components/template/elements/Widget.vue";

export default defineComponent({
    components: {
        Widget,
        AppointmentViewMonthDay,
        FontAwesomeIcon,
        FormSelect
    },
    data () {
        return {
            currentDate: new Date(),
            currentMonth: 0,
            currentYear: 0,
            isLoading: true,
            appointmentState: AppointmentStore.getState(),
            formatDay,
            TimeOption,
            days
        };
    },
    computed: {
        dayOptions () {
            const daysInMonth = getDaysInMonth(this.currentDate);
            return Array.from(new Array(daysInMonth), (x, i) => {
                return {
                    id: (i + 1).toString(),
                    value: i + 1,
                    name: (i + 1).toString().padStart(2, "0")
                };
            });
        },
        weeks () {
            const weeks = [];

            const startDay = startOfISOWeek(startOfMonth(this.currentDate));
            const endDay = endOfISOWeek(endOfMonth(this.currentDate));

            let currentDay = startDay;

            while (isBefore(currentDay, endDay)) {
                if (getDay(currentDay) === 1) {
                    const days = [];
                    for (let day = 0; day < 7; day++) {
                        days.push(currentDay);
                        currentDay = dateAdd(currentDay, { days: 1 });
                    }
                    weeks.push(days);
                }
            }

            return weeks;
        }
    },
    watch: {
        currentMonth () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
        },
        currentYear () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
        },
        async currentDate () {
            this.currentMonth = this.currentDate.getMonth();
            this.currentYear = this.currentDate.getFullYear();

            this.isLoading = true;
            await AppointmentStore.setAppointmentPeriod(startOfMonth(this.currentDate), dateAdd(startOfMonth(this.currentDate), { months: 1 }));
            this.isLoading = false;
        }
    },
    async mounted () {
        const now = new Date();

        this.currentMonth = now.getMonth();
        this.currentYear = now.getFullYear();

        this.isLoading = true;
        await AppointmentStore.setAppointmentPeriod(startOfMonth(this.currentDate), dateAdd(startOfMonth(this.currentDate), { months: 1 }));
        this.isLoading = false;
    },
    methods: {
        async nextMonth (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { months: 1 });
        },
        async previousMonth (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { months: -1 });
        },
        getAppointmentsForDay (date: Date) {
            return _.filter(this.appointmentState.appointments, (appointment) => isSameDay(appointment.startDate, date));
        }
    }
});
</script>

<style scoped lang="scss">
.day-selection {
    display: flex;
    align-items: center;
    justify-content: center;

    .month {
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding: 16px;
        font-size: 28px;
        font-weight: 100;
        text-transform: capitalize;

        .select {
            display: inline-block;
            line-height: 40px;

            &.select-date::v-deep {
                padding: 4px 16px;
                text-align: center;
                text-align-last: center;
            }
        }
    }

    .navigation {
        font-size: 32px;
        color: var(--color-text-light);
        cursor: pointer;
        user-select: none;
        opacity: 0.5;
        transition: 0.2s ease-in-out;

        &:hover {
            color: var(--color-primary);
            opacity: 1;
        }
    }
}

.view {
    padding: 32px;

    table {
        width: 100%;
        border-collapse: collapse;
        table-layout:fixed;

        td {
            border: 1px solid rgba(0,0,0,0.1);
            width: calc(100% / 7);
            vertical-align: top;
        }
    }
}
</style>
