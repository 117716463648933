
import { defineComponent, PropType } from "vue";
import { differenceInMinutes } from "date-fns";
import { DaySlots } from "@/services/appointment.service";
import { formatHour } from "@/utils/date";

export default defineComponent({
    props: {
        availableDates: {
            type: Array as PropType<DaySlots>,
            required: true
        },
        selectedTime: {
            type: String,
            default: ""
        },
        duration: {
            type: Number,
            required: true
        }
    },
    emits: ["select-time"],
    data () {
        return {
            formatHour
        };
    },
    methods: {
        selectTime (time: number): void {
            this.$emit("select-time", time);
        },
        isStickLeft (dateIndex: number): boolean {
            if (dateIndex > 0) {
                return differenceInMinutes(new Date(this.availableDates[dateIndex]), new Date(this.availableDates[dateIndex - 1])) === this.duration;
            }
            return false;
        },
        isStickRight (dateIndex: number): boolean {
            if (dateIndex < this.availableDates.length - 1) {
                return differenceInMinutes(new Date(this.availableDates[dateIndex + 1]), new Date(this.availableDates[dateIndex])) === this.duration;
            }
            return false;
        }
    }
});
