export const days = [
    { id: "monday", value: 1, name: "Lundi" },
    { id: "tuesday", value: 2, name: "Mardi" },
    { id: "wednesday", value: 3, name: "Mercredi" },
    { id: "thursday", value: 4, name: "Jeudi" },
    { id: "friday", value: 5, name: "Vendredi" },
    { id: "saturday", value: 6, name: "Samedi" },
    { id: "sunday", value: 0, name: "Dimanche" }
];

export const months = [
    { id: "january", value: 0, name: "Janvier" },
    { id: "february", value: 1, name: "Février" },
    { id: "march", value: 2, name: "Mars" },
    { id: "april", value: 3, name: "Avril" },
    { id: "may", value: 4, name: "Mai" },
    { id: "june", value: 5, name: "Juin" },
    { id: "july", value: 6, name: "Juillet" },
    { id: "august", value: 7, name: "Aout" },
    { id: "september", value: 8, name: "Septembre" },
    { id: "october", value: 9, name: "Octobre" },
    { id: "november", value: 10, name: "Novembre" },
    { id: "december", value: 11, name: "Décembre" }
];

const currentYear = new Date().getFullYear();

export const years = [
    {
        id: (currentYear - 1).toString(),
        value: currentYear - 1,
        name: (currentYear - 1).toString()
    },
    {
        id: (currentYear).toString(),
        value: currentYear,
        name: (currentYear).toString()
    },
    {
        id: (currentYear + 1).toString(),
        value: currentYear + 1,
        name: (currentYear + 1).toString()
    }
];
