
import { defineComponent, PropType } from "vue";
import { differenceInMinutes, isBefore } from "date-fns";
import FontAwesomeIcon from "@/utils/fontawesome";
import { Appointment } from "@/services/appointment.service";
import { formatHour } from "@/utils/date";
import Widget from "@/components/template/elements/Widget.vue";
import AppointmentStore from "@/stores/appointment.store";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        Widget
    },
    props: {
        appointment: {
            type: Object as PropType<Appointment>,
            required: true
        },
        stickUp: {
            type: Boolean,
            default: false
        },
        stickDown: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            formatHour
        };
    },
    computed: {
        duration (): number {
            return differenceInMinutes(this.appointment.endDate, this.appointment.startDate);
        },
        passed (): boolean {
            return isBefore(this.appointment.endDate, new Date());
        }
    },
    methods: {
        edit () {
            AppointmentStore.editAppointment(this.appointment.id as string);
            document.querySelector(".content")!.scrollTop = 0;
        }
    }
});
