import { startOfDay, isSameDay } from "date-fns";
import _ from "lodash";
import { Store } from "./model";
import AppointmentService, { Appointment, AppointmentType, DaySlots } from "@/services/appointment.service";

interface AppointmentStoreData extends Record<string, unknown> {
    loaded: boolean;
    selectedAppointmentType?: string;
    selectedAppointmentPeriod: {
        start: Date;
        end: Date;
    };
    dateSlots?: Record<string, DaySlots>;
    appointmentTypes: Record<string, AppointmentType>;
    appointments: Array<Appointment>;
    editingAppointmentId?: string;
}

class AppointmentStore extends Store<AppointmentStoreData> {
    protected data (): AppointmentStoreData {
        return {
            loaded: false,
            appointmentTypes: {},
            dateSlots: {},
            appointments: [],
            selectedAppointmentPeriod: { start: new Date(), end: new Date() }
        };
    }

    editAppointment (appointmentId: string) {
        this.state.editingAppointmentId = appointmentId;
    }

    clearEditAppointment () {
        delete this.state.editingAppointmentId;
    }

    async loadAppointments () {
        if (isSameDay(this.state.selectedAppointmentPeriod.start, this.state.selectedAppointmentPeriod.end)) {
            this.state.appointments = await AppointmentService.getAppointments(this.state.selectedAppointmentPeriod.start);
        }
        else {
            this.state.appointments = await AppointmentService.getAppointmentsForPeriod(this.state.selectedAppointmentPeriod.start, this.state.selectedAppointmentPeriod.end);
        }
    }

    async setAppointmentDate (date: Date) {
        this.state.selectedAppointmentPeriod.start = date;
        this.state.selectedAppointmentPeriod.end = date;
        this.state.appointments = [];
        await this.loadAppointments();
    }

    async setAppointmentPeriod (startDate: Date, endDate: Date) {
        this.state.selectedAppointmentPeriod.start = startDate;
        this.state.selectedAppointmentPeriod.end = endDate;
        this.state.appointments = [];
        await this.loadAppointments();
    }

    async load (force = false): Promise<void> {
        if (this.state.loaded && !force) {
            return;
        }
        this.state.appointmentTypes = await AppointmentService.load();
        this.state.loaded = true;
    }

    async loadByPharmacyId (pharmacyId: string, force = false): Promise<void> {
        if (this.state.loaded && !force) {
            return;
        }
        this.state.appointmentTypes = await AppointmentService.loadByPharmacyId(pharmacyId);
        this.state.loaded = true;
    }

    async loadDateSlots (appointmentId: string): Promise<void> {
        this.state.dateSlots = {};
        this.state.dateSlots = await AppointmentService.loadDateSlots(appointmentId);
    }

    async loadCustomDateSlots (pharmacyId: string, duration: number): Promise<void> {
        this.state.dateSlots = {};
        this.state.dateSlots = await AppointmentService.loadCustomDateSlots(pharmacyId, duration);
    }

    addCustomDateSlots (startDate: Date) {
        const date = startOfDay(startDate).toISOString();
        if (this.state.dateSlots?.[date]) {
            this.state.dateSlots[date].push(startDate.toISOString());
            this.state.dateSlots[date] = _.uniq(this.state.dateSlots[date]);
            this.state.dateSlots[date] = this.state.dateSlots[date].sort();
        }
    }

    async getDateSlotsForDay (appointmentId: string, date: Date): Promise<DaySlots> {
        return await AppointmentService.loadDateSlotsForDay(appointmentId, date);
    }

    async createType (appointmentType: AppointmentType): Promise<void> {
        appointmentType = await AppointmentService.createType(appointmentType);
        this.state.appointmentTypes[appointmentType.id as string] = appointmentType;
    }

    async saveType (appointmentType: AppointmentType): Promise<void> {
        if (!this.state.appointmentTypes[appointmentType.id as string].updated) {
            return;
        }

        await AppointmentService.updateType(appointmentType);
        delete this.state.appointmentTypes[appointmentType.id as string].updated;
    }

    updateType (appointmentType: AppointmentType): void {
        if (!this.state.appointmentTypes) {
            return;
        }

        const appointmentTypeId = appointmentType.id as string;

        if (!this.state.appointmentTypes[appointmentTypeId]) {
            return;
        }

        if (this.state.appointmentTypes[appointmentTypeId].name !== appointmentType.name && appointmentType.name !== "") {
            this.state.appointmentTypes[appointmentTypeId].name = appointmentType.name;
            this.state.appointmentTypes[appointmentTypeId].updated = true;
        }

        if (this.state.appointmentTypes[appointmentTypeId].duration !== appointmentType.duration && Number.isFinite(appointmentType.duration)) {
            this.state.appointmentTypes[appointmentTypeId].duration = appointmentType.duration;
            this.state.appointmentTypes[appointmentTypeId].updated = true;
        }
    }

    async removeType (appointmentType: AppointmentType): Promise<void> {
        await AppointmentService.removeType(appointmentType);
        delete this.state.appointmentTypes[appointmentType.id as string];
    }

    async register (appointment: Partial<Appointment>): Promise<void> {
        await AppointmentService.register(appointment);
    }

    async update (appointment: Partial<Appointment>): Promise<void> {
        await AppointmentService.update(appointment);
    }

    async remove (appointment: Partial<Appointment>): Promise<void> {
        await AppointmentService.remove(appointment);
    }
}

const appointmentStore = new AppointmentStore();
export default appointmentStore as AppointmentStore;
