<template>
    <div class="list">
        <div class="day-selection">
            <FontAwesomeIcon
                icon="angle-double-left"
                class="navigation"
                @click="previousDay"
            />
            <div class="day">
                <div class="select select-day">
                    {{ formatDay(currentDate) }}
                </div>
                <FormSelect
                    v-model="currentDay"
                    input-id="day"
                    label=""
                    :options="dayOptions"
                    class="select select-date"
                />
                <FormSelect
                    v-model="currentMonth"
                    input-id="month"
                    label=""
                    :options="TimeOption.months"
                    class="select select-month"
                />
                <FormSelect
                    v-model="currentYear"
                    input-id="year"
                    label=""
                    :options="TimeOption.years"
                    class="select select-month"
                />
            </div>
            <FontAwesomeIcon
                icon="angle-double-right"
                class="navigation"
                @click="nextDay"
            />
        </div>
        <transition-group
            name="list"
            tag="div"
        >
            <AppointmentCard
                v-for="(appointment, appointmentIndex) of appointmentState.appointments"
                :key="appointment.id"
                :appointment="appointment"
                :stick-up="isStickUp(appointmentIndex)"
                :stick-down="isStickDown(appointmentIndex)"
                class="list"
            />
        </transition-group>
        <div
            v-if="isLoading"
            class="loading"
        >
            <FontAwesomeIcon
                icon="spinner"
                spin
            />
        </div>
        <div
            v-else-if="appointmentState.appointments.length === 0"
            class="empty"
        >
            Aucun rendez-vous pour cette date
        </div>
        <SmallButton
            class="button"
            @click="exportCSV"
        >
            Exporter en CSV
        </SmallButton>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { add as dateAdd, getDaysInMonth, isEqual as dateIsEqual } from "date-fns";
import AppointmentCard from "@/components/appointment/AppointmentCard.vue";
import { formatHour, formatDay } from "@/utils/date";
import FontAwesomeIcon from "@/utils/fontawesome";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import * as TimeOption from "@/components/timetable/timeOptions";
import AppointmentStore from "@/stores/appointment.store";
import SmallButton from "@/components/template/elements/SmallButton.vue";

export default defineComponent({
    components: {
        AppointmentCard,
        FontAwesomeIcon,
        FormSelect,
        SmallButton
    },
    data () {
        return {
            currentDate: new Date(),
            currentDay: 0,
            currentMonth: 0,
            currentYear: 0,
            isLoading: true,
            appointmentState: AppointmentStore.getState(),
            formatDay,
            TimeOption
        };
    },
    computed: {
        dayOptions () {
            const daysInMonth = getDaysInMonth(this.currentDate);
            return Array.from(new Array(daysInMonth), (x, i) => {
                return {
                    id: (i + 1).toString(),
                    value: i + 1,
                    name: (i + 1).toString().padStart(2, "0")
                };
            });
        }
    },
    watch: {
        currentDay () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, this.currentDay);
        },
        currentMonth () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, this.currentDay);
        },
        currentYear () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, this.currentDay);
        },
        async currentDate () {
            this.currentDay = this.currentDate.getDate();
            this.currentMonth = this.currentDate.getMonth();
            this.currentYear = this.currentDate.getFullYear();

            this.isLoading = true;
            await AppointmentStore.setAppointmentDate(this.currentDate);
            this.isLoading = false;
        }
    },
    async mounted () {
        const now = new Date();

        this.currentDay = now.getDate();
        this.currentMonth = now.getMonth();
        this.currentYear = now.getFullYear();

        this.isLoading = true;
        await AppointmentStore.setAppointmentDate(this.currentDate);
        this.isLoading = false;
    },
    methods: {
        async nextDay (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { days: 1 });
        },
        async previousDay (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { days: -1 });
        },
        isStickUp (appointmentIndex: number): boolean {
            if (appointmentIndex > 0) {
                return dateIsEqual(this.appointmentState.appointments[appointmentIndex].startDate, this.appointmentState.appointments[appointmentIndex - 1].endDate);
            }
            return false;
        },
        isStickDown (appointmentIndex: number): boolean {
            if (appointmentIndex < this.appointmentState.appointments.length - 1) {
                return dateIsEqual(this.appointmentState.appointments[appointmentIndex].endDate, this.appointmentState.appointments[appointmentIndex + 1].startDate);
            }
            return false;
        },
        exportCSV (): void {
            const exportTable = [];
            exportTable.push(["Prénom", "Nom", "Mail", "Téléphone", "Rendez-vous", "Début", "Fin"].join(","));

            for (const appointment of this.appointmentState.appointments) {
                const exportAppointment = [
                    appointment.firstName,
                    appointment.lastName,
                    appointment.mail,
                    appointment.phone,
                    this.appointmentState.appointmentTypes[appointment.type as string].name,
                    formatHour(appointment.startDate),
                    formatHour(appointment.endDate)
                ];

                exportTable.push(exportAppointment.join(","));
            }
            this.downloadFile("export.csv", exportTable.join("\n"));
        },
        downloadFile (filename: string, data: string) {
            const csvContent = `data:text/csv;charset=utf-8,${data}`;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
});
</script>

<style scoped lang="scss">
.day-selection {
    display: flex;
    align-items: center;
    justify-content: center;

    .day {
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: 400px;
        padding: 16px;
        font-size: 28px;
        font-weight: 100;
        text-transform: capitalize;

        .select {
            display: inline-block;
            line-height: 40px;

            &.select-day {
                width: 120px;
                padding: 4px 0;
                text-align: right;
                margin-bottom: 13px;
                position: relative;

                &::before {
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;
                    height: 1px;
                    content: "";
                    background: var(--color-shadow-soft);
                }
            }

            &.select-date::v-deep {
                padding: 4px 16px;
                text-align: center;
                text-align-last: center;
            }
        }
    }

    .navigation {
        font-size: 32px;
        color: var(--color-text-light);
        cursor: pointer;
        user-select: none;
        opacity: 0.5;
        transition: 0.2s ease-in-out;

        &:hover {
            color: var(--color-primary);
            opacity: 1;
        }
    }
}

.list {
    height: 96px;
    opacity: 1;
}

.list-enter-active,
.list-leave-active {
    transition: all .5s ease-in-out;
}

.list-enter-from,
.list-leave-to {
    height: 0;
    opacity: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.loading {
    text-align: center;
    font-size: 48px;
    color: var(--color-text-light);
    opacity: .5;
}

.empty {
    text-align: center;
    color: var(--color-text-light);
    opacity: .5;
    font-size: 32px;
    font-weight: 100;
}
</style>
