
import { defineComponent } from "vue";
import PanelLayout from "@/layouts/PanelLayout.vue";
import AppointmentForm from "@/components/appointment/AppointmentForm.vue";
import AppointmentViewDay from "@/components/appointment/AppointmentViewDay.vue";
import AppointmentViewMonth from "@/components/appointment/AppointmentViewMonth.vue";
import SmallButton from "@/components/template/elements/SmallButton.vue";

export default defineComponent({
    components: {
        SmallButton,
        AppointmentForm,
        AppointmentViewDay,
        AppointmentViewMonth,
        PanelLayout
    },
    data () {
        return {
            viewType: "day"
        };
    },
    methods: {
        selectViewType (type: string) {
            this.viewType = type;
        },
        getButtonColor (type: string) {
            return (type === this.viewType) ? "green" : "white";
        },
        setDate (date: string) {
            (this.$refs.appointmentViewDay as any).currentDate = new Date(date);
        }
    }
});
