import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import PharmacyStore from "@/stores/pharmacy.store";

const routes: Array<RouteRecordRaw> = [
    {
        component: Home,
        name: "home",
        path: "/",
        meta: {
            pharmacyAccess: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "rdv" */ "../views/Rdv.vue"),
        name: "rdv",
        path: "/rdv/:url",
        meta: {
            guest: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        name: "login",
        path: "/login",
        meta: {
            guest: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "subscribe" */ "../views/Subscribe.vue"),
        name: "subscribe",
        path: "/subscribe",
        meta: {
            guest: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "timetable" */ "../views/Timetable.vue"),
        name: "timetable",
        path: "/timetable",
        meta: {
            pharmacyAccess: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "appointment" */ "../views/Appointment.vue"),
        name: "appointment",
        path: "/appointment",
        meta: {
            pharmacyAccess: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "billing" */ "../views/Billing.vue"),
        name: "billing",
        path: "/billing",
        meta: {
            pharmacyAccess: true
        }
    },
    {
        component: () => import(/* webpackChunkName: "appointment" */ "../views/Settings.vue"),
        name: "settings",
        path: "/settings",
        meta: {
            pharmacyAccess: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (targetPage, originPage, next) => {
    if (targetPage.matched.some(record => record.meta.guest)) {
        return next();
    }

    if (targetPage.matched.some(record => record.meta.pharmacyAccess)) {
        const isConnected = await PharmacyStore.isConnected();
        if (isConnected) {
            return next();
        }

        return next({
            path: "/login",
            params: { nextUrl: targetPage.fullPath }
        });
    }

    return next();
});

export default router;
