
import { defineComponent } from "vue";
import * as Yup from "yup";
import { addMinutes, differenceInMinutes, isEqual, startOfDay } from "date-fns";
import _ from "lodash";
import Widget from "@/components/template/elements/Widget.vue";
import AppointmentStore from "@/stores/appointment.store";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";
import { Appointment } from "@/services/appointment.service";
import FormInput from "@/components/template/elements/FormInput.vue";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import DateSelection from "@/components/appointment/DateSelection.vue";
import ResponsiveInput from "@/components/mobile/elements/ResponsiveInput.vue";
import AppointmentHourSelect from "@/components/appointment/AppointmentHourSelect.vue";

export default defineComponent({
    components: {
        AppointmentHourSelect,
        DateSelection,
        FontAwesomeIcon,
        FormSelect,
        FormInput,
        FormSpacer,
        Widget
    },
    data () {
        return {
            appointment: {} as Appointment,
            appointmentState: AppointmentStore.getState(),
            appointmentOptions: [] as Array<{ id: string; value: number | string; name: string }>,
            appointmentDuration: 0,
            selectedDate: "",
            selectedTime: "",
            mailRules: Yup.string()
                .email("Vous devez entrer un email valide."),
            firstNameRules: Yup.string()
                .required("Vous devez entrer un prénom.")
                .min(2, "Votre prénom doit contenir au moins 2 caractères."),
            lastNameRules: Yup.string()
                .required("Vous devez entrer un nom de famille.")
                .min(2, "Votre nom de famille doit contenir au moins 2 caractères.")
        };
    },
    watch: {
        "appointmentState.appointmentTypes": {
            immediate: true,
            deep: true,
            handler () {
                this.appointmentOptions = [];

                for (const appointmentType of Object.keys(this.appointmentState.appointmentTypes)) {
                    const appointment = this.appointmentState.appointmentTypes[appointmentType];
                    this.appointmentOptions.push({
                        name: `${appointment.name} (${appointment.duration}min)`,
                        id: appointment.id as string,
                        value: appointment.id as string
                    });
                }
            }
        },
        "appointmentState.appointments": {
            handler () {
                this.clear();
            }
        },
        "appointment.type": {
            async handler () {
                this.appointmentDuration = this.appointmentState.appointmentTypes[this.appointment.type as string].duration;
                await AppointmentStore.loadCustomDateSlots(this.appointment.pharmacy as string, this.appointmentDuration);
                AppointmentStore.addCustomDateSlots(this.appointment.startDate);
            }
        },
        "appointmentState.editingAppointmentId": {
            immediate: true,
            async handler () {
                this.appointment = _.find(this.appointmentState.appointments, { id: this.appointmentState.editingAppointmentId }) as Appointment;

                if (this.appointment) {
                    this.appointmentDuration = differenceInMinutes(this.appointment.endDate, this.appointment.startDate);
                    this.selectedDate = startOfDay(this.appointment.startDate).toISOString();
                    this.selectedTime = this.appointment.startDate.toISOString();
                    await AppointmentStore.loadCustomDateSlots(this.appointment.pharmacy as string, this.appointmentDuration);
                    AppointmentStore.addCustomDateSlots(this.appointment.startDate);
                }
                else {
                    this.clear();
                }
            }
        }
    },
    methods: {
        selectDate (date: string) {
            this.selectedDate = date;
        },
        selectTime (time: string) {
            this.selectedTime = time;
        },
        async checkForm (): Promise<boolean> {
            let isValid = true;

            isValid = isValid && await (this.$refs.firstNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.lastNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.mailInput as typeof ResponsiveInput).checkValidity();

            return isValid;
        },
        async clear () {
            AppointmentStore.clearEditAppointment();
            await AppointmentStore.loadAppointments();
        },
        async remove () {
            await AppointmentStore.remove(this.appointment);
            await this.clear();
        },
        async save () {
            const isValid = await this.checkForm();
            if (isValid) {
                let startDate: Date|undefined = new Date(this.selectedTime);
                let endDate: Date|undefined = addMinutes(startDate, this.appointmentDuration);

                if (isEqual(startDate, this.appointment.startDate) && isEqual(endDate, this.appointment.endDate)) {
                    startDate = undefined;
                    endDate = undefined;
                }

                const appointment: Partial<Appointment> = {
                    id: this.appointment.id,
                    startDate,
                    endDate,
                    type: this.appointment.type,
                    firstName: this.appointment.firstName,
                    lastName: this.appointment.lastName,
                    mail: this.appointment.mail,
                    phone: this.appointment.phone
                };

                await AppointmentStore.update(appointment);
                await this.clear();
            }
        }
    }
});
