
import FontAwesomeIcon from "@/utils/fontawesome";

export default {
    name: "MenuButton",
    components: {
        FontAwesomeIcon
    },
    props: {
        active: Boolean,
        name: { type: String, default: "" },
        icon: { type: String, default: "" }
    },
    emits: ["click"]
};
