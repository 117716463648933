<template>
    <Widget class="widget">
        <div class="type-select">
            <span class="new">Ajouter un rendez-vous:</span>
            <FormSpacer class="spacer" />
            <FormSelect
                v-model="selectedAppointment"
                class="appointment-select"
                input-id="appointment-type"
                label="Type de rendez-vous"
                :options="appointmentOptions"
            />
        </div>
        <div
            v-if="selectedAppointment !== ''"
            class="date-select"
        >
            <DateSelection
                v-for="(dateSlot, date) of appointmentState.dateSlots"
                v-show="dateSlot.length"
                :key="date"
                class="date"
                :date-slot="{date, slots: dateSlot}"
                :selected="date === selectedDate"
                @click="selectDate(date)"
            />
        </div>
        <AppointmentHourSelect
            v-if="selectedDate !== ''"
            :available-dates="appointmentState.dateSlots[selectedDate]"
            :duration="appointmentState.appointmentTypes[selectedAppointment].duration"
            :selected-time="selectedTime"
            @select-time="selectTime"
        />
        <div
            v-if="selectedTime"
            class="form"
        >
            <FormInput
                ref="firstNameInput"
                v-model="firstName"
                input-id="first-name"
                label="Prénom*"
                :rules="firstNameRules"
                @submit="submit"
            />
            <FormSpacer class="spacer" />
            <FormInput
                ref="lastNameInput"
                v-model="lastName"
                input-id="last-name"
                label="Nom de famille*"
                :rules="lastNameRules"
                @submit="submit"
            />
            <FormSpacer class="spacer" />
            <FormInput
                ref="mailInput"
                v-model="mail"
                input-id="mail"
                label="Email"
                :rules="mailRules"
                @submit="submit"
            />
            <FormSpacer class="spacer" />
            <FormInput
                v-model="phone"
                input-id="phone"
                label="Téléphone"
            />
        </div>
        <div
            class="create"
            :class="{display:isFullfilled}"
            @click="submit"
        >
            <FontAwesomeIcon
                icon="calendar-plus"
                class="icon"
            />
        </div>
    </Widget>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as Yup from "yup";
import Widget from "@/components/template/elements/Widget.vue";
import AppointmentStore from "@/stores/appointment.store";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";
import { Appointment, DaySlots } from "@/services/appointment.service";
import FormInput from "@/components/template/elements/FormInput.vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import DateSelection from "@/components/appointment/DateSelection.vue";
import ResponsiveInput from "@/components/mobile/elements/ResponsiveInput.vue";
import AppointmentHourSelect from "@/components/appointment/AppointmentHourSelect.vue";

export default defineComponent({
    components: {
        AppointmentHourSelect,
        DateSelection,
        FontAwesomeIcon,
        FormInput,
        FormSelect,
        FormSpacer,
        Widget
    },
    emits: ["select-date"],
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            appointmentOptions: [] as Array<{ id: string; value: number | string; name: string }>,
            selectedAppointment: "",
            availableDates: [] as DaySlots,
            selectedDate: "",
            selectedTime: 0,
            firstName: "",
            lastName: "",
            mail: "",
            phone: "",
            mailRules: Yup.string()
                .email("Vous devez entrer un email valide."),
            firstNameRules: Yup.string()
                .required("Vous devez entrer un prénom.")
                .min(2, "Votre prénom doit contenir au moins 2 caractères."),
            lastNameRules: Yup.string()
                .required("Vous devez entrer un nom de famille.")
                .min(2, "Votre nom de famille doit contenir au moins 2 caractères.")
        };
    },
    computed: {
        isFullfilled (): boolean {
            return (this.firstName !== "" && this.lastName !== "");
        }
    },
    watch: {
        "appointmentState.appointmentTypes": {
            immediate: true,
            deep: true,
            handler () {
                this.appointmentOptions = [];

                for (const appointmentType of Object.keys(this.appointmentState.appointmentTypes)) {
                    const appointment = this.appointmentState.appointmentTypes[appointmentType];
                    this.appointmentOptions.push({
                        name: `${appointment.name} (${appointment.duration}min)`,
                        id: appointment.id as string,
                        value: appointment.id as string
                    });
                }
            }
        },
        async selectedAppointment () {
            if (this.selectedAppointment !== "") {
                await AppointmentStore.loadDateSlots(this.selectedAppointment);
            }
        }
    },
    async mounted () {
        await AppointmentStore.load();
    },
    methods: {
        selectDate (date: string) {
            this.selectedDate = date;
            this.$emit("select-date", date);
        },
        selectTime (hour: number) {
            this.selectedTime = hour;
        },
        async checkForm (): Promise<boolean> {
            let isValid = true;

            isValid = isValid && await (this.$refs.firstNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.lastNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.mailInput as typeof ResponsiveInput).checkValidity();

            return isValid;
        },
        async submit () {
            const isValid = await this.checkForm();
            if (isValid) {
                const appointment: Partial<Appointment> = {
                    appointmentTypeId: this.selectedAppointment,
                    startDate: new Date(this.selectedTime),
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mail: this.mail,
                    phone: this.phone
                };

                await AppointmentStore.register(appointment);
                await AppointmentStore.loadAppointments();

                this.firstName = "";
                this.lastName = "";
                this.mail = "";
                this.selectedDate = "";
                this.selectedTime = 0;
                this.selectedAppointment = "";
            }
        }
    }
});
</script>

<style scoped lang="scss">
.widget {
    position: relative;
    padding: 8px 16px;
    overflow: hidden;
}

.type-select,
.form {
    display: flex;
    align-items: flex-start;
}

.new {
    padding: 4px 0;margin-top: 16px;
    font-weight: 800;
    border-bottom: 1px solid var(--color-shadow-soft);
}

.appointment-select {
    min-width: 180px;
}

.spacer {
    width: 16px;
}

.date-select {
    display: flex;
    flex-wrap: wrap;

    .date {
        width: 80px;
        margin: 4px;
    }
}

.create {
    position: absolute;
    right: -64px;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    overflow: hidden;
    font-size: 32px;
    color: var(--color-text-lightest);
    cursor: pointer;
    background: var(--color-primary);
    border-top-left-radius: 32px;
    transition: .2s ease-in-out;

    & .icon {
        opacity: 0.7;
        transition: .1s ease-in-out;
    }

    &:hover .icon {
        opacity: 1;
    }

    &.display {
        right:0;
    }
}
</style>
