import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ApiService from "@/services/api.service";

const currentUrl = new URL(window.location.href);
if (process.env.VUE_APP_BACKEND_PORT) {
    ApiService.init(`${currentUrl.protocol}//${process.env.VUE_APP_BACKEND_HOST}:${process.env.VUE_APP_BACKEND_PORT}`);
}
else {
    ApiService.init(`${currentUrl.protocol}//${process.env.VUE_APP_BACKEND_HOST}`);
}

createApp(App as never)
    .use(router)
    .mount("#app");
