<template>
    <div class="widget">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
.widget {
    border-radius: 32px;
    box-shadow:
        var(--color-shadow-soft) 5px 5px 10px,
        var(--color-light-hard) -5px -5px 10px;
}
</style>
