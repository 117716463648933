<template>
    <div class="form-select-wrapper">
        <div class="form-select">
            <label
                :for="inputId"
                :class="{hasContent}"
            >
                {{ label }}
            </label>
            <select
                :id="inputId"
                v-model="value"
                :name="inputId"
            >
                <option
                    v-for="option in options"
                    :key="option.id"
                    :value="option.value"
                >
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            type: [String, Number],
            default: ""
        },
        inputId: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        options: {
            type: Array as PropType<Array<{id: string; value: number | string; name: string}>>,
            required: true
        }
    },
    emits: ["update:modelValue"],
    data () {
        return {
            value: "" as string | number
        };
    },
    computed: {
        hasContent (): boolean {
            return this.value !== "";
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler () {
                this.value = this.modelValue;
            }
        },
        value () {
            this.$emit("update:modelValue", this.value);
        }
    }
});
</script>

<style scoped lang="scss">
.form-select-wrapper {
    position: relative;
    padding: 4px 0;
    margin: 14px 0;
    font-size: inherit;
    pointer-events: none;

    .form-select {
        label {
            position: absolute;
            top: 5px;
            left: 1px;
            color: var(--color-text-light);
            pointer-events: none;
            transition: ease-in-out 0.2s;

            &.hasContent {
                top: -12px;
                font-size: 14px;
            }
        }

        select {
            font-weight: inherit;
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
            font-size: inherit;
            pointer-events: auto;
            cursor: pointer;
            background: transparent;
            border: 0;
            border-radius: 0;
            appearance: none;

            &:focus,
            &:active {
                outline: none;
            }
        }

        &::before {
            position: absolute;
            right: 0;
            bottom: -1px;
            left: 0;
            height: 1px;
            content: "";
            background: var(--color-shadow-soft);
        }

        &::after {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            height: 2px;
            pointer-events: none;
            content: "";
            background: var(--color-primary);
            transition: ease-out 0.2s;
        }

        &:focus-within {
            label {
                top: -12px;
                font-size: 14px;
                color: var(--color-primary);
            }

            &::after {
                width: 100%;
            }
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
}
</style>
