
import { defineComponent, PropType } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formatHour } from "@/utils/date";
import { Appointment } from "@/services/appointment.service";
import AppointmentStore from "@/stores/appointment.store";

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        appointment: {
            type: Object as PropType<Appointment>,
            required: true
        }
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            formatHour
        };
    }
});
