<template>
    <div class="form-spacer" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped lang="scss">
.form-spacer {
    border-bottom: 1px solid var(--color-shadow-soft);
    margin-bottom: 13px;
    margin-top: 46px;
}
</style>
