
import { defineComponent } from "vue";
import AppointmentFormAdd from "@/components/appointment/AppointmentFormAdd.vue";
import AppointmentFormEdit from "@/components/appointment/AppointmentFormEdit.vue";
import AppointmentStore from "@/stores/appointment.store";

export default defineComponent({
    components: {
        AppointmentFormAdd,
        AppointmentFormEdit
    },
    emits: ["select-date"],
    data () {
        return {
            appointmentState: AppointmentStore.getState()
        };
    },
    methods: {
        selectDate (date: string) {
            this.$emit("select-date", date);
        }
    }
});
