<template>
    <Widget
        class="card"
        :class="{'stick-up':stickUp, 'stick-down':stickDown}"
    >
        <div
            class="head"
            :class="{passed}"
        >
            <div class="time">
                <div class="start-time">
                    {{ formatHour(appointment.startDate) }}
                </div>
                <div class="end-time">
                    <FontAwesomeIcon icon="caret-right" />
                    {{ formatHour(appointment.endDate) }}
                </div>
                <div class="duration">
                    ({{ duration }} min)
                </div>
            </div>
        </div>
        <div class="content">
            <div class="main">
                <div class="name">
                    {{ appointment.firstName }} {{ appointment.lastName }}
                </div>
                <div class="description">
                    {{ appointmentState.appointmentTypes[appointment.type]?.name || "Inconnu" }}
                </div>
            </div>
            <div class="contact">
                <div
                    v-if="appointment.mail"
                    class="mail"
                >
                    <FontAwesomeIcon icon="at" />
                    {{ appointment.mail }}
                </div>
                <div
                    v-if="appointment.phone"
                    class="phone"
                >
                    <FontAwesomeIcon icon="phone" />
                    {{ appointment.phone }}
                </div>
            </div>
            <div
                class="icon"
                @click="edit"
            >
                <FontAwesomeIcon icon="edit" />
            </div>
        </div>
    </Widget>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { differenceInMinutes, isBefore } from "date-fns";
import FontAwesomeIcon from "@/utils/fontawesome";
import { Appointment } from "@/services/appointment.service";
import { formatHour } from "@/utils/date";
import Widget from "@/components/template/elements/Widget.vue";
import AppointmentStore from "@/stores/appointment.store";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        Widget
    },
    props: {
        appointment: {
            type: Object as PropType<Appointment>,
            required: true
        },
        stickUp: {
            type: Boolean,
            default: false
        },
        stickDown: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            formatHour
        };
    },
    computed: {
        duration (): number {
            return differenceInMinutes(this.appointment.endDate, this.appointment.startDate);
        },
        passed (): boolean {
            return isBefore(this.appointment.endDate, new Date());
        }
    },
    methods: {
        edit () {
            AppointmentStore.editAppointment(this.appointment.id as string);
            document.querySelector(".content")!.scrollTop = 0;
        }
    }
});
</script>

<style scoped lang="scss">
.card {
    display: flex;
    margin: 16px;
    overflow: hidden;
    background: var(--color-background);

    &.stick-up {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.stick-down {
        margin-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .head {
        display: flex;
        justify-content: flex-end;
        width: 140px;
        padding: 16px 24px;
        color: var(--color-text-lightest);
        background: var(--color-primary);

        &.passed{
            background: var(--color-text-light);
        }

        .time {
            display: grid;
            grid-template:
                "start end"
                "start duration";
            height: 50px;

            .start-time {
                grid-area: start;
                font-size: 24px;
                text-align: right;
            }

            .end-time {
                grid-area: end;
                padding-left: 8px;
                font-weight: 800;
            }

            .duration {
                grid-area: duration;
                padding-left: 8px;
                opacity: 0.7;
            }
        }
    }

    .content {
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 16px 24px;

        .main {

            width: 100%;
        }

        .name {
            font-size: 28px;
            font-weight: 100;
        }

        .contact {
            width: 200px;
        }

        .contact, .icon {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &:hover {
        .icon {
            opacity: 0.7;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 100%;
        font-size: 32px;
        color: var(--color-text-light);
        cursor: pointer;
        opacity: 0;
        transition: ease-in-out 0.1s;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
