<template>
    <div
        class="date-selection"
        :class="{selected}"
    >
        <div class="day">
            {{ day }}
        </div>
        <div class="day-number">
            {{ dayNumber }}
        </div>
        <div class="month">
            {{ month }}
        </div>
        <div class="slots">
            ({{ dateSlot.slots.length }} places)
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import { DateSlot } from "@/services/appointment.service";

export default defineComponent({
    props: {
        dateSlot: {
            type: Object as PropType<DateSlot>,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        day (): string {
            return dateFormat(new Date(this.dateSlot.date), "EEEE", { locale: dateFr });
        },
        month (): string {
            return dateFormat(new Date(this.dateSlot.date), "MMMM", { locale: dateFr });
        },
        dayNumber (): number {
            return (new Date(this.dateSlot.date)).getDate();
        }
    }
});
</script>

<style scoped lang="scss">
.date-selection {
    text-align: center;
    cursor: pointer;
    box-shadow: var(--color-shadow-soft) 5px 5px 10px,
    var(--color-light-hard) -5px -5px 10px;
    border-radius: 16px;
    border: 1px solid var(--color-primary);
    box-sizing: border-box;

    &.selected {
        border: 3px solid var(--color-text);
    }

    .day {
        font-weight: 800;
        color: var(--color-primary);
        text-transform: capitalize;
    }

    .month {
        text-transform: capitalize;
    }

    .day-number {
        font-size: 24px;
        font-weight: 800;
        background: var(--color-primary);
        color: var(--color-text-lightest);
    }

    .slots {
        color: var(--color-text-light);
        font-size: 12px
    }
}
</style>
