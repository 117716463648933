<template>
    <div class="title">
        <img
            src="../../assets/logo.png"
            alt="Logo"
            class="logo"
        >
        <span class="text">ResaPharm</span>
    </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.title {
    display: flex;
    align-items: center;
    padding: 16px 16px 16px 32px;

    .logo {
        width: 40px;
        margin-right: 8px;
    }

    .text {
        color: var(--color-text-lightest);
        font-size: 28px;
        font-weight: 600;

        @media screen and (max-width: 1400px) {
            display: none;
        }
    }
}
</style>
