
import { defineComponent } from "vue";
import { add as dateAdd, getDaysInMonth, isEqual as dateIsEqual } from "date-fns";
import AppointmentCard from "@/components/appointment/AppointmentCard.vue";
import { formatHour, formatDay } from "@/utils/date";
import FontAwesomeIcon from "@/utils/fontawesome";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import * as TimeOption from "@/components/timetable/timeOptions";
import AppointmentStore from "@/stores/appointment.store";
import SmallButton from "@/components/template/elements/SmallButton.vue";

export default defineComponent({
    components: {
        AppointmentCard,
        FontAwesomeIcon,
        FormSelect,
        SmallButton
    },
    data () {
        return {
            currentDate: new Date(),
            currentDay: 0,
            currentMonth: 0,
            currentYear: 0,
            isLoading: true,
            appointmentState: AppointmentStore.getState(),
            formatDay,
            TimeOption
        };
    },
    computed: {
        dayOptions () {
            const daysInMonth = getDaysInMonth(this.currentDate);
            return Array.from(new Array(daysInMonth), (x, i) => {
                return {
                    id: (i + 1).toString(),
                    value: i + 1,
                    name: (i + 1).toString().padStart(2, "0")
                };
            });
        }
    },
    watch: {
        currentDay () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, this.currentDay);
        },
        currentMonth () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, this.currentDay);
        },
        currentYear () {
            this.currentDate = new Date(this.currentYear, this.currentMonth, this.currentDay);
        },
        async currentDate () {
            this.currentDay = this.currentDate.getDate();
            this.currentMonth = this.currentDate.getMonth();
            this.currentYear = this.currentDate.getFullYear();

            this.isLoading = true;
            await AppointmentStore.setAppointmentDate(this.currentDate);
            this.isLoading = false;
        }
    },
    async mounted () {
        const now = new Date();

        this.currentDay = now.getDate();
        this.currentMonth = now.getMonth();
        this.currentYear = now.getFullYear();

        this.isLoading = true;
        await AppointmentStore.setAppointmentDate(this.currentDate);
        this.isLoading = false;
    },
    methods: {
        async nextDay (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { days: 1 });
        },
        async previousDay (event: Event) {
            event.preventDefault();
            this.currentDate = dateAdd(this.currentDate, { days: -1 });
        },
        isStickUp (appointmentIndex: number): boolean {
            if (appointmentIndex > 0) {
                return dateIsEqual(this.appointmentState.appointments[appointmentIndex].startDate, this.appointmentState.appointments[appointmentIndex - 1].endDate);
            }
            return false;
        },
        isStickDown (appointmentIndex: number): boolean {
            if (appointmentIndex < this.appointmentState.appointments.length - 1) {
                return dateIsEqual(this.appointmentState.appointments[appointmentIndex].endDate, this.appointmentState.appointments[appointmentIndex + 1].startDate);
            }
            return false;
        },
        exportCSV (): void {
            const exportTable = [];
            exportTable.push(["Prénom", "Nom", "Mail", "Téléphone", "Rendez-vous", "Début", "Fin"].join(","));

            for (const appointment of this.appointmentState.appointments) {
                const exportAppointment = [
                    appointment.firstName,
                    appointment.lastName,
                    appointment.mail,
                    appointment.phone,
                    this.appointmentState.appointmentTypes[appointment.type as string].name,
                    formatHour(appointment.startDate),
                    formatHour(appointment.endDate)
                ];

                exportTable.push(exportAppointment.join(","));
            }
            this.downloadFile("export.csv", exportTable.join("\n"));
        },
        downloadFile (filename: string, data: string) {
            const csvContent = `data:text/csv;charset=utf-8,${data}`;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
});
