<template>
    <AdminLayout v-if="displayAdminLayout">
        <template #sidebar>
            <Sidebar />
        </template>
        <template #content>
            <router-view />
        </template>
    </AdminLayout>
    <router-view v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import Sidebar from "@/components/template/Sidebar.vue";

export default defineComponent({
    components: {
        AdminLayout,
        Sidebar
    },
    computed: {
        displayAdminLayout (): boolean {
            return this.$route.meta.pharmacyAccess;
        }
    }
});
</script>

<style lang="scss">
@import "style";
</style>
