<template>
    <div class="panel-layout">
        <h1>{{ title }}</h1>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ""
        }
    }
};
</script>

<style scoped lang="scss">
.panel-layout {
    padding: 64px 128px;

    h1 {
        display: block;
        padding: 16px 0;
        margin: 0;
        font-size: 36px;
        font-weight: 600;
    }

    ::v-deep(*::selection) {
        color: var(--color-text-lightest);
        background-color: var(--color-primary);
    }
}

</style>
