<template>
    <div
        class="view-month-day"
        :class="{greyed: !isCurrentMonth, today: isToday}"
    >
        <span class="day">
            {{ displayDay }}
        </span>
        <div
            v-for="appointment in appointments"
            :key="appointment.id"
        >
            <AppointmentViewMonthCard :appointment="appointment" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
    getDate, isSameDay,
    isSameMonth
} from "date-fns";
import { Appointment } from "@/services/appointment.service";
import AppointmentViewMonthCard from "@/components/appointment/AppointmentViewMonthCard.vue";

export default defineComponent({
    components: { AppointmentViewMonthCard },
    props: {
        date: {
            type: Date as PropType<Date>,
            required: true
        },
        currentDate: {
            type: Date as PropType<Date>,
            required: true
        },
        appointments: {
            type: Array as PropType<Array<Appointment>>,
            required: true
        }
    },
    computed: {
        displayDay (): number {
            return getDate(this.date);
        },
        isCurrentMonth (): boolean {
            return isSameMonth(this.date, this.currentDate);
        },
        isToday (): boolean {
            return isSameDay(this.date, new Date());
        }
    }
});
</script>

<style scoped lang="scss">
.view-month-day {
    min-height: 64px;
    padding: 4px 8px;
    text-align: center;

    .day {
        display: inline-block;
    }

    &.today {
        .day {
            background: var(--color-primary);
            padding: 0 4px;
            border-radius: 8px;
            color: var(--color-text-lightest);
            font-weight: bold;
        }
    }

    &.greyed {
        opacity: 0.3;
    }

    .day {
        font-size: 12px;
        text-align: center;
    }
}
</style>
