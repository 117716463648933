
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            type: [String, Number],
            default: ""
        },
        inputId: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        options: {
            type: Array as PropType<Array<{id: string; value: number | string; name: string}>>,
            required: true
        }
    },
    emits: ["update:modelValue"],
    data () {
        return {
            value: "" as string | number
        };
    },
    computed: {
        hasContent (): boolean {
            return this.value !== "";
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler () {
                this.value = this.modelValue;
            }
        },
        value () {
            this.$emit("update:modelValue", this.value);
        }
    }
});
