import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAt,
    faCalendar,
    faCalendarAlt,
    faCalendarPlus,
    faCaretRight,
    faCog,
    faCopy,
    faEdit,
    faFileInvoice,
    faHome,
    faPhone,
    faPlus,
    faSave,
    faTimes,
    faTrash,
    faList,
    faSpinner,
    faCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAt,
    faCalendar,
    faCalendarAlt,
    faCalendarPlus,
    faCaretRight,
    faCog,
    faCopy,
    faEdit,
    faFileInvoice,
    faHome,
    faPhone,
    faPlus,
    faSave,
    faTimes,
    faTrash,
    faList,
    faSpinner,
    faCheck
);

export default FontAwesomeIcon;
