import ApiService from "./api.service";
import * as DateUtils from "@/utils/date";

export interface DateSlot {
    date: string;
    slots: DaySlots;
}

export interface DaySlots extends Array<string> {
    [index: number]: string;
}

export interface AppointmentType {
    id?: string;
    name: string;
    message?: string;
    duration: number;
    updated?: boolean;
    incomingNumber?: number;
}

export interface Appointment {
    id?: string;
    pharmacy?: string;
    type?: string;
    appointmentTypeId: string;
    startDate: Date;
    endDate: Date;
    firstName: string;
    lastName: string;
    mail: string;
    phone: string;
}

const AppointmentService = {
    async load (): Promise<Record<string, AppointmentType>> {
        return await ApiService.get("/appointment/type/list") as Record<string, AppointmentType>;
    },

    async loadByPharmacyId (pharmacyId: string): Promise<Record<string, AppointmentType>> {
        return await ApiService.get(`/pharmacy/${pharmacyId}/appointment/type/list`) as Record<string, AppointmentType>;
    },

    async loadDateSlots (appointmentId: string): Promise<Record<string, DaySlots>> {
        return await ApiService.get(`/appointment/available-dates/type/${appointmentId}`) as Record<string, DaySlots>;
    },

    async loadCustomDateSlots (pharmacyId: string, duration: number): Promise<Record<string, DaySlots>> {
        return await ApiService.get(`/appointment/available-dates/pharmacy/${pharmacyId}/duration/${duration}`) as Record<string, DaySlots>;
    },

    async loadDateSlotsForDay (appointmentId: string, date: Date): Promise<DaySlots> {
        return await ApiService.post("/appointment/available-dates", {
            appointmentId,
            date
        }) as DaySlots;
    },

    async createType (appointmentType: AppointmentType): Promise<AppointmentType> {
        return await ApiService.post("/appointment/type/create", {
            name: appointmentType.name,
            message: appointmentType.message,
            duration: appointmentType.duration
        }) as AppointmentType;
    },

    async updateType (appointmentType: AppointmentType): Promise<void> {
        await ApiService.put("/appointment/type/update", {
            appointmentId: appointmentType.id,
            name: appointmentType.name,
            message: appointmentType.message,
            duration: appointmentType.duration
        });
    },

    async removeType (appointmentType: AppointmentType): Promise<void> {
        await ApiService.delete("/appointment/type/remove", {
            appointmentId: appointmentType.id
        });
    },

    async register (appointment: Partial<Appointment>): Promise<void> {
        await ApiService.post("/appointment/register", appointment);
    },

    async update (appointment: Partial<Appointment>): Promise<void> {
        await ApiService.put("/appointment/update", appointment);
    },

    async remove (appointment: Partial<Appointment>): Promise<void> {
        await ApiService.delete("/appointment/remove", appointment);
    },

    async getAppointments (date: Date): Promise<Array<Appointment>> {
        const dateString = DateUtils.dateSetUTC(date, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }).toISOString();
        const appointments = (await ApiService.post("/appointment", { date: dateString })) as Array<Appointment>;

        for (let appointmentIndex = 0; appointmentIndex < appointments.length; appointmentIndex++) {
            appointments[appointmentIndex].startDate = new Date(appointments[appointmentIndex].startDate);
            appointments[appointmentIndex].endDate = new Date(appointments[appointmentIndex].endDate);
        }

        return appointments;
    },

    async getAppointmentsForPeriod (startDate: Date, endDate: Date): Promise<Array<Appointment>> {
        const startDateString = DateUtils.dateSetUTC(startDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }).toISOString();

        const endDateString = DateUtils.dateSetUTC(endDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }).toISOString();

        const appointments = (await ApiService.post("/appointment/list", { startDate: startDateString, endDate: endDateString })) as Array<Appointment>;

        for (let appointmentIndex = 0; appointmentIndex < appointments.length; appointmentIndex++) {
            appointments[appointmentIndex].startDate = new Date(appointments[appointmentIndex].startDate);
            appointments[appointmentIndex].endDate = new Date(appointments[appointmentIndex].endDate);
        }

        return appointments;
    }
};

export default AppointmentService;
