import { set as dateSet, addMinutes, subMinutes, format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";

export function formatTime (time: number) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours.toString()}:${minutes.toString().padStart(2, "0")}`;
}

export function formatDate (date: Date) {
    return dateFormat(date, "EEEE d MMMM", { locale: dateFr });
}

export function formatHour (date: Date) {
    return dateFormat(date, "H:mm", { locale: dateFr });
}

export function formatDay (date: Date) {
    return dateFormat(date, "EEEE", { locale: dateFr });
}

export function dateSetUTC (date: Date, options: {
    year?: number;
    month?: number;
    date?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
}): Date {
    date = dateSet(date, options);

    const offset = date.getTimezoneOffset();

    return Math.sign(offset) !== -1 ? subMinutes(date, offset) : addMinutes(date, Math.abs(offset));
}
