<template>
    <div class="sidebar-background">
        <div class="sidebar">
            <Logo class="logo" />
            <MenuButton
                v-for="menuItem in menuList"
                :key="menuItem.id"
                :active="isCurrentPage(menuItem.path)"
                :icon="menuItem.icon"
                :name="menuItem.name"
                @click="goToPage(menuItem.path)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/components/template/Logo.vue";
import MenuButton from "@/components/template/menu/MenuButton.vue";

const menuList = [
    {
        icon: "list",
        path: "home",
        name: "Accueil"
    },
    {
        icon: "calendar",
        path: "appointment",
        name: "Rendez-vous"
    },
    {
        icon: "calendar-alt",
        path: "timetable",
        name: "Emploi du temps"
    },
    // {
    //    icon: "file-invoice",
    //    path: "billing",
    //    name: "Facturation"
    // },
    {
        icon: "cog",
        path: "settings",
        name: "Paramètres"
    }
];

export default defineComponent({
    components: { MenuButton, Logo },
    data () {
        return {
            menuList
        };
    },
    methods: {
        goToPage (page: string): void {
            this.$router.push({ name: page });
        },
        isCurrentPage (page: string): boolean {
            return page === this.$route.name;
        }
    }
});
</script>

<style scoped lang="scss">
.sidebar-background {
    min-height: 100vh;
    background: var(--color-primary);

    .sidebar {
        box-sizing: border-box;
        min-height: 100vh;
        padding: 64px 0 64px 48px;
        background: linear-gradient(90deg, hsla(0, 0, 0, 0) 75%, hsla(0, 0, 0, 0.05) 100%);
    }

    .logo {
        margin-bottom: 32px;
    }

    ::v-deep(*::selection) {
        color: var(--color-primary);
        background-color: var(--color-text-lightest);
    }
}
</style>
