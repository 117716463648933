<template>
    <Widget class="widget">
        <div class="type-select">
            <span class="new">Editer un rendez-vous:</span>
            <FormSpacer class="spacer" />
            <FormSelect
                v-model="appointment.type"
                class="appointment-select"
                input-id="appointment-type"
                label="Type de rendez-vous"
                :options="appointmentOptions"
            />
        </div>
        <div class="form">
            <FormInput
                ref="firstNameInput"
                v-model="appointment.firstName"
                input-id="first-name"
                label="Prénom"
                :rules="firstNameRules"
            />
            <FormSpacer class="spacer" />
            <FormInput
                ref="lastNameInput"
                v-model="appointment.lastName"
                input-id="last-name"
                label="Nom de famille"
                :rules="lastNameRules"
            />
            <FormSpacer class="spacer" />
            <FormInput
                ref="mailInput"
                v-model="appointment.mail"
                input-id="mail"
                label="Email"
                :rules="mailRules"
            />
            <FormSpacer class="spacer" />
            <FormInput
                v-model="appointment.phone"
                input-id="phone"
                label="Téléphone"
            />
        </div>
        <div class="date-select">
            <DateSelection
                v-for="(dateSlot, date) of appointmentState.dateSlots"
                :key="date"
                class="date"
                :date-slot="{date, slots: dateSlot}"
                :selected="date === selectedDate"
                @click="selectDate(date)"
            />
        </div>
        <AppointmentHourSelect
            :available-dates="appointmentState.dateSlots[selectedDate]"
            :duration="appointmentDuration"
            :selected-time="selectedTime"
            @select-time="selectTime"
        />
        <div
            class="clear"
            @click="clear"
        >
            <FontAwesomeIcon
                icon="times"
                class="icon"
            />
        </div>
        <div
            class="remove"
            @click="remove"
        >
            <FontAwesomeIcon
                icon="trash"
                class="icon"
            />
        </div>
        <div
            class="save"
            @click="save"
        >
            <FontAwesomeIcon
                icon="save"
                class="icon"
            />
        </div>
    </Widget>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as Yup from "yup";
import { addMinutes, differenceInMinutes, isEqual, startOfDay } from "date-fns";
import _ from "lodash";
import Widget from "@/components/template/elements/Widget.vue";
import AppointmentStore from "@/stores/appointment.store";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";
import { Appointment } from "@/services/appointment.service";
import FormInput from "@/components/template/elements/FormInput.vue";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import DateSelection from "@/components/appointment/DateSelection.vue";
import ResponsiveInput from "@/components/mobile/elements/ResponsiveInput.vue";
import AppointmentHourSelect from "@/components/appointment/AppointmentHourSelect.vue";

export default defineComponent({
    components: {
        AppointmentHourSelect,
        DateSelection,
        FontAwesomeIcon,
        FormSelect,
        FormInput,
        FormSpacer,
        Widget
    },
    data () {
        return {
            appointment: {} as Appointment,
            appointmentState: AppointmentStore.getState(),
            appointmentOptions: [] as Array<{ id: string; value: number | string; name: string }>,
            appointmentDuration: 0,
            selectedDate: "",
            selectedTime: "",
            mailRules: Yup.string()
                .email("Vous devez entrer un email valide."),
            firstNameRules: Yup.string()
                .required("Vous devez entrer un prénom.")
                .min(2, "Votre prénom doit contenir au moins 2 caractères."),
            lastNameRules: Yup.string()
                .required("Vous devez entrer un nom de famille.")
                .min(2, "Votre nom de famille doit contenir au moins 2 caractères.")
        };
    },
    watch: {
        "appointmentState.appointmentTypes": {
            immediate: true,
            deep: true,
            handler () {
                this.appointmentOptions = [];

                for (const appointmentType of Object.keys(this.appointmentState.appointmentTypes)) {
                    const appointment = this.appointmentState.appointmentTypes[appointmentType];
                    this.appointmentOptions.push({
                        name: `${appointment.name} (${appointment.duration}min)`,
                        id: appointment.id as string,
                        value: appointment.id as string
                    });
                }
            }
        },
        "appointmentState.appointments": {
            handler () {
                this.clear();
            }
        },
        "appointment.type": {
            async handler () {
                this.appointmentDuration = this.appointmentState.appointmentTypes[this.appointment.type as string].duration;
                await AppointmentStore.loadCustomDateSlots(this.appointment.pharmacy as string, this.appointmentDuration);
                AppointmentStore.addCustomDateSlots(this.appointment.startDate);
            }
        },
        "appointmentState.editingAppointmentId": {
            immediate: true,
            async handler () {
                this.appointment = _.find(this.appointmentState.appointments, { id: this.appointmentState.editingAppointmentId }) as Appointment;

                if (this.appointment) {
                    this.appointmentDuration = differenceInMinutes(this.appointment.endDate, this.appointment.startDate);
                    this.selectedDate = startOfDay(this.appointment.startDate).toISOString();
                    this.selectedTime = this.appointment.startDate.toISOString();
                    await AppointmentStore.loadCustomDateSlots(this.appointment.pharmacy as string, this.appointmentDuration);
                    AppointmentStore.addCustomDateSlots(this.appointment.startDate);
                }
                else {
                    this.clear();
                }
            }
        }
    },
    methods: {
        selectDate (date: string) {
            this.selectedDate = date;
        },
        selectTime (time: string) {
            this.selectedTime = time;
        },
        async checkForm (): Promise<boolean> {
            let isValid = true;

            isValid = isValid && await (this.$refs.firstNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.lastNameInput as typeof ResponsiveInput).checkValidity();
            isValid = isValid && await (this.$refs.mailInput as typeof ResponsiveInput).checkValidity();

            return isValid;
        },
        async clear () {
            AppointmentStore.clearEditAppointment();
            await AppointmentStore.loadAppointments();
        },
        async remove () {
            await AppointmentStore.remove(this.appointment);
            await this.clear();
        },
        async save () {
            const isValid = await this.checkForm();
            if (isValid) {
                let startDate: Date|undefined = new Date(this.selectedTime);
                let endDate: Date|undefined = addMinutes(startDate, this.appointmentDuration);

                if (isEqual(startDate, this.appointment.startDate) && isEqual(endDate, this.appointment.endDate)) {
                    startDate = undefined;
                    endDate = undefined;
                }

                const appointment: Partial<Appointment> = {
                    id: this.appointment.id,
                    startDate,
                    endDate,
                    type: this.appointment.type,
                    firstName: this.appointment.firstName,
                    lastName: this.appointment.lastName,
                    mail: this.appointment.mail,
                    phone: this.appointment.phone
                };

                await AppointmentStore.update(appointment);
                await this.clear();
            }
        }
    }
});
</script>

<style scoped lang="scss">
.widget {
    position: relative;
    padding: 8px 16px;
    overflow: hidden;
}

.type-select,
.form {
    display: flex;
    align-items: flex-start;
}

.new {
    padding: 4px 0;
    margin-top: 16px;
    font-weight: 800;
    border-bottom: 1px solid var(--color-shadow-soft);
}

.appointment-select {
    min-width: 180px;
}

.spacer {
    width: 16px;
}

.date-select {
    display: flex;
    flex-wrap: wrap;

    .date {
        width: 80px;
        margin: 4px;
    }
}

.clear, .remove, .save {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    overflow: hidden;
    font-size: 32px;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.clear {
    right: 0;
    top: 0;
    color: var(--color-text-light);
    & .icon {
        opacity: 0.7;
        transition: .1s ease-in-out;
    }

    &:hover .icon {
        opacity: 1;
    }
}

.remove {
    right: 64px;
    top: 0;
    color: var(--color-text-light);
    & .icon {
        opacity: 0.7;
        transition: .1s ease-in-out;
    }

    &:hover .icon {
        opacity: 1;
    }
}

.save {
    right: 0;
    bottom: 0;
    color: var(--color-text-lightest);
    background: var(--color-primary);
    border-top-left-radius: 32px;

    & .icon {
        opacity: 0.7;
        transition: .1s ease-in-out;
    }

    &:hover .icon {
        opacity: 1;
    }
}
</style>
